<template>
  <div class="main">
    <div class="m_top"></div>
    <div class="w1200 m_bg p-t-39">
      <div class="text-c">
        <p class="font30 color333 weight">专家推荐</p>
        <p class="font16 color999">Experts recommend</p>
      </div>
      <div class="m-t-28 m-b-30 m-con">
        <div class="flex-between">
          <div class="m_recom m-b-25" v-for="(item, i) in ReList" :key="i">
            <div class="recom_box">
              <div class="img_re">
                <div class="top_img img200">
                  <img class="ReImg" :src="item.headImg" alt="" />
                </div>
                <div class="font20 color333">{{ item.name }}</div>
                <div class="colorccc font14">
                  {{ item.label }} ｜{{ item.rank }}
                </div>
                <div class="color666 font14">
                  {{ item.hospital }} {{ item.label }}
                </div>
                <div
                  class="btn colorfff m-t-20 hand"
                  @click="btn_det(item.lecturerId)"
                >
                  查看课程
                  <img
                    class="img12 m-l-4"
                    src="../assets/images/icon_16.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CourseItem from "../components/CourseItem.vue";
import { lecturer } from "@/api/home";
export default {
  components: {},
  data() {
    return {
      ReList: [],
    };
  },
  created() {
    this.getReList();
  },
  methods: {
    //分页
    change(v) {
      this.order_page = v;
      this.getReList();
    },
    getReList() {
      lecturer({ pageNum: 1, pageSize: 20 }).then((res) => {
        this.ReList = res.data.data;
      });
    },
    btn_det(lecturerId) {
      this.$router.push({ path: "/RecomDetail", query: { lecturerId } });
      console.log("lecturerId", lecturerId);
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  background: #f8f8f8;
}
.m_top {
  height: 540px;
  background: url("../assets/images/recom.png") no-repeat top/ auto;
}
.m_bg {
}
.m_recom {
  width: 280px;
  height: 440px;
  background: #ffffff;
  .recom_box {
    padding: 20px;
    height: 440px;
    background: url("../assets/images/img_13.png") no-repeat 50% 50%;
    .img_re {
      text-align: center;
      height: 360px;
      margin: 20px;
      line-height: 26px;
      .top_img {
        width: 200px;
        height: 200px;
        margin-bottom: 20px;
        background-color: burlywood;
      }
      .btn {
        width: 110px;
        height: 26px;
        background-color: #cccccc;
        box-shadow: 0px 2px 4px 0px rgba(201, 101, 0, 0.2);
        border-radius: 13px;
        margin: 10px auto;
      }
      .ReImg {
        width: 100%;
        height: 100%;
      }
    }
    &:hover {
      .btn {
        background: #ffa54b;
      }
    }
  }
}
</style>